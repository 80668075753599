@charset 'utf-8';

@media (min-width: 48em) {
  .heroes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 72em) {
  .heroes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 96em) {
  .heroes {
    grid-template-columns: repeat(4, 1fr);
  }
}